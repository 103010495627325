/**   Purple    **/
$purple: #2a1843;
$dark-purple: #2a1843;
$light-purple: #2a1843;

/**   greys   **/
$silver: #d7d7d7;
$mid-grey: #cccccc;
$placeholder: #efefef;
$light-grey: #f3f3f6;
$white: #ffffff;
$mid-dark-grey: #c4c4c4;
$off-white: #fefefe;

/**   Gold    **/
$gold: #c3a660;
$translucent_gold: #D3A45666;

/**   reds    **/
$light-translucent-red: #ff000022;
$bootstrap-danger: #a94442;

/** Blog Colours **/
$base_colour: #FCF9F5;
$accent_colour: #A8A1DB;
$hover_colour: #CCA562;


@mixin font($size) {
    @if $size == script {
        font-family: 'baskerville', serif;
        letter-spacing: 0.03em;
    } @else if $size == light {
        font-family: 'proxima-nova', sans-serif;
        letter-spacing: 0.05em;
        font-weight: 300;
    } @else if $size == thin {
        font-family: 'proxima-nova', sans-serif;
        font-weight: 100;
        letter-spacing: 0.05em;
    } @else if $size == reg {
        font-family: 'proxima-nova', sans-serif;
        font-weight: 400;
        letter-spacing: 0.05em;
    } @else if $size == bold {
        font-family: 'proxima-nova', sans-serif;
        letter-spacing: 0.05em;
        font-weight: 700;
    } @else if $size == xbold {
        letter-spacing: 0.05em;
        font-family: 'proxima-nova', sans-serif;
        font-weight: 900;
    } @else if $size == "main_header" {
        font-size: 2.1875rem;
    } @else if $size == "important_subheader" {
        font-size: 1.71875rem;
    } @else if $size == "subheader" {
        font-size: 1.5rem;
    } @else if $size == "standard_text" {
        font-size: 1.09375rem;
    } @else if $size == "large_text_link" {
        font-size: 1.25rem;
    } @else if $size == "small_text_link" {
        font-size: 0.9375rem;
    }
}

$mobile-menu-width: 255px;

$screen-xs: 480px;
$screen-sm: 768px;
$screen-md: 992px;
$screen-lg: 1200px;
$screen-xl: 1400px;

$screen-xs-max: ($screen-sm - 1);
$screen-sm-max: ($screen-md - 1);
$screen-md-max: ($screen-lg - 1);
$screen-lg-max: ($screen-xl - 1);

/**   Breakpoints    **/
@mixin breakpoint($point) {
    @if $point == XS {
        @media (max-width: $screen-xs-max) {
            @content;
        }
    } @else if $point == SM {
        @media (min-width: $screen-sm) {
            @content;
        }
    } @else if $point == MD {
        @media (min-width: $screen-md) {
            @content;
        }
    } @else if $point == LG {
        @media (min-width: $screen-lg) {
            @content;
        }
    } @else if $point == XLG {
        @media (min-width: $screen-xl) {
            @content;
        }
    } @else if $point == SM-MAX {
        @media screen and (max-width: $screen-sm-max) {
            @content;
        }
    } @else if $point == MD-MAX {
        @media screen and (max-width: $screen-md-max) {
            @content;
        }
    } @else if $point == LG-MAX {
        @media screen and (max-width: $screen-lg-max) {
            @content;
        }
    }
}

@mixin price-alert($background, $color) {
    background-color: $background;
    color: $color;
}