@import "../variables";
@import "../transition";

.popup-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  transition: opacity 0.3s linear, z-index 0.5s step-end;
  z-index: -1;
  display: none;

  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&0.65+0,0+100;Neutral+Density */
  background: -moz-radial-gradient(center, ellipse cover,  rgba(0,0,0,0.65) 0%, rgba(0,0,0,0) 100%); /* FF3.6-15 */
  background: -webkit-radial-gradient(center, ellipse cover,  rgba(0,0,0,0.65) 0%,rgba(0,0,0,0) 100%); /* Chrome10-25,Safari5.1-6 */
  background: radial-gradient(ellipse at center,  rgba(0,0,0,0.65) 0%,rgba(0,0,0,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a6000000', endColorstr='#00000000',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */

  &.active {
    display: block;
    opacity: 1;
    z-index: 500;
    transition: opacity 0.3s linear;
    overflow: scroll;
  }

  .popup {
    position: relative;
    width: 95%;
    margin: 0 auto;
    padding: 0 15px;
    background-color: $dark-purple;
    color: white;
    border: 5px solid rgba(white, 0.2);

    @include breakpoint(SM) {
      padding: 0 25px;
    }

    a {
      &:hover {
        color: white;
      }
    }

    .secondary-market-alternatives {
      color: $dark-purple;
      font-weight: bold;
      &:hover {
        color:$gold;
      }
    }

    div.close {
      position: absolute;
      top: 12px;
      right: 14px;
      width: 25px;
      height: 25px;
      cursor: pointer;
      font-size: 20px;
      @include transition(0.5s);
      opacity: 0.6;
      background: url(/images/white-close.png) no-repeat center;

      &:hover {
        opacity: 1;
      }
    }

    .content {
      text-align: center;

      h1 {
        font-size: 38px;
        @include font(script);
        letter-spacing: 0;
        margin: 0 auto;
      }

      h2 {
        font-size: 32px;
        @include font(script);
        margin: 0 auto 30px;
      }

      .description {
        margin-bottom: 20px;
        p {
          margin-bottom: 10px;
        }
      }

      .divider-points {
        margin: 15px auto;
      }

      .info, .message {
        @include font(light);
        font-size: 16px;

        &:not(:last-child) {
          margin-bottom: 25px;
        }
      }
    }
  } // .popup
} // .popup-overlay